import {StartOptions, StartOptionsText, StartOptionsUl} from "../pages/AnimationSet";
import {motion} from "framer-motion";
import React from "react";

const ShowOptions = ({label1, label2, label3, func1, func2, func3}) => {
    const myPages = [
        {label: label1, function: func1, dx:0},
        {label: label2, function: func2, dx:1},
        {label: label3, function: func3, dx:2}
    ];

    return (
        <motion.ul variants={StartOptionsUl} className="trioptions">
            {myPages.map((page) => (
                <motion.li variants={StartOptions}>
                    <button onClick={page.function} style={{marginLeft: `${page.dx*0}vw`}}>
                        <motion.a variants={StartOptionsText}>{page.label}</motion.a>
                        <img src="/pic/options.png" alt="" className="dialog_options_pic"/>
                    </button>
                </motion.li>
            ))}
        </motion.ul>
    );
}

export default ShowOptions;