import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Stage, Sprite, Container, Graphics } from '@pixi/react';
import './Home.css';
import { motion } from "framer-motion";
import StatusBook from "./StatusBook";
import { Texture } from "pixi.js";
import {useNavigate} from "react-router-dom";
import {NormalTransition} from "./AnimationSet";

const Home = ({ ifOpenBook }) => {
    const dHeight = -0.35;
    const homeSize = 3;
    const [canvasSize, setCanvasSize] = useState({width: window.innerWidth, height: window.innerHeight});
    const spriteRef = useRef(null);
    const stageRef = useRef(null);
    const graphicsRef = useRef(null);
    const windowRef = useRef(null);
    const navigate = useNavigate();
    const [openBook, setOpenBook] = useState(ifOpenBook);
    const [bookUrl, setBookUrl] = useState("/pic/book.gif");
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [squareIndex, setSquareIndex] = useState({ x: 0, y: 0 });
    const [charaIndex, setCharaIndex] = useState({ x: 0, y: 0});
    const [scrollOffset, setScrollOffset] = useState(0)
    const charaIndexDetect = useMemo(() => ({ x: charaIndex.x, y: charaIndex.y }), [charaIndex]);
    const squaresCount = {x: 32, y: 15}; // each window
    const squareSize = {x: canvasSize.width / squaresCount.x, y: canvasSize.height / squaresCount.y};
    const forbiddenPlace = useMemo(() => [
        [17, 2], [17, 3], [17, 4], [17, 5], [17, 6], [17, 10], [17, 11], [17, 12], [17, 13],
        [18, 12], [18, 13], [19, 12], [19, 13], [20, 12], [20, 13], [21, 12], [21, 13],
        [22, 1], [22, 2], [22, 3], [22, 4], [22, 5], [22, 6], [22, 12], [22, 13],
        [23, 1], [23, 2], [23, 3], [23, 4], [23, 5], [23, 6], [23, 12], [23, 13],
        [24, 1], [24, 2], [24, 3], [24, 4], [24, 5], [24, 6], [24, 12], [24, 13],
        [25, 1], [25, 2], [25, 3], [25, 4], [25, 5], [25, 6], [25, 12], [25, 13],
        [26, 1], [26, 2], [26, 12], [26, 13],
        [27, 1], [27, 2], [27, 12], [27, 13],
        [28, 1], [28, 2], [28, 3], [28, 4], [28, 5], [28, 6], [28, 12], [28, 13],
        [32, 12], [32, 13], [33, 12], [33, 13], [34, 12], [34, 13], [35, 12], [35, 13], [36, 12], [36, 13], [37, 12], [37, 13],
        [38, 2], [38, 12], [38, 13], [39, 12], [39, 13], [40, 12], [40, 13], [41, 12], [41, 13],
        [42, 3], [42, 4], [42, 12], [42, 13],
        [43, 12], [43, 13], [44, 12], [44, 13], [45, 12], [45, 13], [46, 12], [46, 13], [47, 12], [47, 13],
    ], []);


    useEffect(() => {
        const centerPos = (window.innerWidth) / 2;

        const Initial = () => {

            if (spriteRef.current) {
                spriteRef.current.texture = Texture.from("/pic/chara_down.png");
            }
            if (windowRef.current) {
                windowRef.current.scrollTo(centerPos, 0);
                setScrollOffset(centerPos / squareSize.x);
            }
            setCharaIndex(prevState => ({
                ...prevState,
                x: 14.5 + centerPos / squareSize.x,
                y: 8.5 + dHeight
            }));
        };

        Initial();

        const handleResize = () => {
            setCanvasSize({ width: window.innerWidth, height: window.innerHeight });
            stageRef.current.width = window.innerWidth*2;
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [squareSize.x, dHeight]);

    useEffect(() => {
        const sprite = spriteRef.current;

        const ifCanPlaceOn = (targetX, targetY) => {
            return !forbiddenPlace.some(place => place[0] === targetX && place[1] === targetY);
        }

        const ifCanWalkOn = (targetX, targetY) => {
            return !forbiddenPlace.some(place =>
                (place[0] === targetX || place[0] === targetX - 0.5 || place[0] === targetX - 1)
                && (place[1] === targetY - dHeight || place[1] === targetY - 0.5 - dHeight || place[1] === targetY - 1 - dHeight)
            );
        }

        const ifTargetPortal = (targetX, targetY) =>{
            return (targetX === 79 && targetY === 7);
        }

        const playerPositionBlock = () => {
            const { current: graphics } = graphicsRef;
            graphics.clear();
            setSquareIndex({ x: Math.floor(mousePosition.x / squareSize.x) + scrollOffset, y: Math.floor(mousePosition.y / squareSize.y) });

            if (ifCanPlaceOn(squareIndex.x, squareIndex.y)){
                graphics.beginFill("#ccc", 0.7);
            }
            else{
                graphics.beginFill("#a11", 0.7);
            }
            graphics.drawRect(squareIndex.x * squareSize.x, squareIndex.y * squareSize.y, squareSize.x, squareSize.y);
            graphics.endFill();
        }


        const moveToMouse = () => {
            const sprite = spriteRef.current;
            const mouseX = (squareIndex.x + 0.5) * squareSize.x;
            const mouseY = (squareIndex.y + 0.5) * squareSize.y;

            if (Math.abs(mouseX - sprite.x) > Math.abs(mouseY - sprite.y)) {
                if (mouseX > sprite.x) {
                    sprite.texture = Texture.from("/pic/chara_right.png");
                } else {
                    sprite.texture = Texture.from("/pic/chara_left.png");
                }
            } else {
                if (mouseY < sprite.y) {
                    sprite.texture = Texture.from("/pic/chara_up.png");
                } else {
                    sprite.texture = Texture.from("/pic/chara_down.png");
                }
            }

            if (ifCanPlaceOn(squareIndex.x, squareIndex.y)) {
                setCharaIndex({ x: squareIndex.x + 0.5, y: squareIndex.y + 0.5 + dHeight });
                charaIndexDetect.x = squareIndex.x + 0.5;
                charaIndexDetect.y = squareIndex.y + 0.5 + dHeight;
                if (ifTargetPortal(squareIndex.x, squareIndex.y)){
                    navigate('/Blog');
                }
            }
        }

        const judgeMoveDirection = (dx, dy, face_direct) => {
            if (sprite.x + dx >= 0 && sprite.x + dx <= window.innerWidth*homeSize && sprite.y + dy >= 0 && sprite.y + dy < window.innerHeight &&ifCanWalkOn(charaIndexDetect.x + dx, charaIndexDetect.y + dy)) {
                setCharaIndex(prev => ({
                    ...prev,
                    x: prev.x + dx,
                    y: prev.y + dy
                }));
                charaIndexDetect.x += dx;
                charaIndexDetect.y += dy;
            }
            if (ifTargetPortal(charaIndexDetect.x - 0.5, charaIndexDetect.y - dHeight -0.5)){
                navigate('/Blog');
            }
            if (dx > 0 && sprite.x + dx > window.innerWidth+((scrollOffset-2)*squareSize.x) && dx <= window.innerWidth*homeSize){
                if (windowRef.current){
                    windowRef.current.scrollBy(squareSize.x, 0);
                    if ((scrollOffset * squareSize.x) < (homeSize - 1) * window.innerWidth){
                        setScrollOffset(scrollOffset+1);
                    }
                }
            }
            else if (dx < 0 && sprite.x + dx < ((2 + scrollOffset)*squareSize.x)){
                if (windowRef.current){
                    if (windowRef.current.scrollLeft > 0){
                        setScrollOffset(scrollOffset-1);
                    }
                    windowRef.current.scrollBy(-1*squareSize.x, 0);
                }
            }
            sprite.texture = Texture.from(`/pic/chara_${face_direct}.png`);
        };

        const handleKeyDown = (event) => {
            switch (event.key) {
                case 'w':
                    judgeMoveDirection(0, -0.5, "up");
                    break;
                case 'a':
                    judgeMoveDirection(-0.5, 0, "left");
                    break;
                case 's':
                    judgeMoveDirection(0, 0.5, "down");
                    break;
                case 'd':
                    judgeMoveDirection(0.5, 0, "right");
                    break;
                case 'ArrowRight':
                case 'ArrowLeft':
                    window.event.returnValue = false;
                    break;
                default:
                    break;
            }
        };

        const handleMouseMove = (event) => {
            setMousePosition({ x: event.clientX, y: event.clientY });
            playerPositionBlock();
        };

        const handleMouseClick = () => {
            moveToMouse();
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('click', handleMouseClick);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('click', handleMouseClick);
        };
    }, [navigate, scrollOffset, mousePosition.x, mousePosition.y, squareIndex.x, squareIndex.y, squareSize.x, squareSize.y, dHeight, charaIndexDetect, forbiddenPlace]);

    return (
        <motion.div variants={NormalTransition} initial="initial" animate="animate" exit="exit">
            <div className="myhome">
                <div ref={windowRef} className="container">
                    <Stage ref={stageRef} width={canvasSize.width * homeSize} height={canvasSize.height} interactive>
                        <Container x={0} y={0}>
                            <Sprite image="/pic/map/Main.png" x={0} y={0}
                                    width={canvasSize.width * homeSize} height={canvasSize.height}/>
                        </Container>
                        <Container>
                            <Graphics ref={graphicsRef}/>
                        </Container>
                        <Container>
                            <Sprite
                                image={"/pic/charaD.png"}
                                x={charaIndex.x * squareSize.x}
                                y={charaIndex.y * squareSize.y}
                                scale={1.5 * canvasSize.width / 1800}
                                ref={spriteRef}
                                anchor={0.5}
                            />
                        </Container>
                    </Stage>
                </div>
                <button className="openbook" onClick={() => {
                    setOpenBook(true)
                }}>
                    Hello
                </button>
                <div className="bookview">
                    {openBook && <StatusBook closeBook={openBook} setCloseBook={setOpenBook} bookUrl={bookUrl}
                                             setBookUrl={setBookUrl}/>}
                </div>
            </div>
        </motion.div>
    );
}

export default Home;
