import React, {useEffect, useRef, useState} from 'react';
import {
    OpenInputBox, StartChara, StartDialog, DialogSkipButton, StartPage, StartScene, StartCharaPortrait
} from "./AnimationSet";
import {motion} from "framer-motion";
import {useNavigate} from "react-router-dom";
import TypingEffect from "../plugins/TypingEffect";
import ShowOptions from "../plugins/ShowOptions";
import ChangeBackground from "../plugins/ChangeBackground";
import useRWD from "../plugins/useRWD";

const Start = ({setEnterStatus}) =>{
    const inputRef = useRef(null);
    const device= useRWD();
    const navigate = useNavigate();
    const [allText, setAllText] = useState([''])
    const [dialogIndex, setDialogIndex] = useState(0);
    const [userName, setUserName] = useState("");
    const [eventIndex, setEventIndex] = useState([]);
    const [backgroundIndex, setBackgroundIndex] = useState(1);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchTextFile();
        }, 2000);

        const Initiation = (textLine) =>{
            setAllText(textLine);
            setEventIndex([
                textLine.findIndex((element) => element.includes("請問您的名字是")), // 第一項
                textLine.length-1 // 第二項
            ]);
            if (localStorage.getItem("username")){
                setUserName(localStorage.getItem("username"));
                setEventIndex([
                    (-1), // 第一項
                    textLine.length-1 // 第二項
                ]);
                setAllText((prev) =>{
                    const newArray = [...prev];
                    newArray[0] = localStorage.getItem("username") + newArray[0];
                    return newArray;
                })
                // console.log(localStorage.getItem("username"));
            }
        }

        const fetchTextFile = async () => {
            try {
                let response = await fetch("/text/StartText.txt");
                if (localStorage.getItem("username")){
                    response = await fetch("/text/StartText2.txt");
                }
                const text = await response.text();
                const lines = text.split(/\r?\n/);
                Initiation(lines);
            }
            catch (error) {
                console.error("Error fetching and parsing text file:", error);
            }
        };

        return () => clearTimeout(timeoutId);
    }, []);

    const checkNowEvent = (index) =>{
        return dialogIndex === eventIndex[index]
    }
    const nextDialog = (event) => {
        if (!event || event.target.tagName.toLowerCase() !== 'button') {
            if (!(eventIndex.includes(dialogIndex))){
                setDialogIndex((dialogIndex + 1) % allText.length);
            }
        }
    };

    const skipDialog = () =>{
        // console.log(eventIndex[0]);
        for(let i = 0; i < eventIndex.length; i++){
            if(dialogIndex < eventIndex[i]){
                setDialogIndex(eventIndex[i]);
                // console.log(eventIndex);
                break;
            }
        }
    };

    const confirmName = () => {
        const name = inputRef.current.value;
        if (name === ''){
            setUserName(name);
            console.log(userName);
            setAllText((prev) =>{
                const newArray = [...prev];
                newArray.splice(eventIndex[0]+1, 0, "您似乎不願意透漏的您的名字", "那麼就讓我先稱呼您為賓客吧");
                return newArray;
            });
            setEventIndex((prev) =>{
                const newArray = [...prev];
                newArray[newArray.length-1] += 2;
                return newArray
            });
        }
        else{
            localStorage.setItem("username", name);
            setUserName(name);
            setAllText((prev) =>{
                const newArray = [...prev];
                newArray[eventIndex[0]+1] = name + "大人嗎　" + newArray[eventIndex[0]+1];
                return newArray;
            });
        }

        setDialogIndex((dialogIndex + 1) % allText.length);
    };

    return (
        <div>
            <div className="StartScene">
                {checkNowEvent(0) &&
                    <motion.div variants={OpenInputBox} initial="initial" animate="animate" exit="exit2"
                                className="StartNameBox">
                        <div className="inputBg">
                            <input type="text" ref={inputRef} placeholder="輸入你的名字" className="inputBox"/>
                            <button onClick={confirmName} className="inputButton">確定</button>
                        </div>
                    </motion.div>
                }
                <motion.div variants={StartPage} initial="initial" animate="animate" exit="exit2" className="blank"
                            style={{backgroundColor: '#111', zIndex: -1}}/>
                <motion.div variants={StartPage} initial="initial" animate="animate" exit="exit3" className="blank"
                            style={{backgroundColor: '#333', zIndex: -2}}/>
                <motion.div variants={StartPage} initial="initial" animate="animate" exit="exit4" className="blank"
                            style={{backgroundColor: '#444', zIndex: -3}}/>
                <motion.div variants={StartPage} initial="initial" animate="animate" exit="exit" style={{width: '100%', position: "relative"}}>
                    <ChangeBackground setIndex={setBackgroundIndex}/>
                    <motion.div variants={StartScene} className="bg" onClick={nextDialog}
                                style={{backgroundImage: `url(/pic/Startbg${backgroundIndex}.jpg)`}}/>
                    {device==="pc" && <motion.img variants={StartChara} src="pic/nina.png" className="chara"/>}
                    {device==="mobile" && <motion.img variants={StartCharaPortrait} src="pic/nina.png" className="chara"/>}
                    <motion.div variants={StartDialog} onClick={nextDialog} className="dialog_box">
                        <img src="/pic/dialog_box.png" alt="" className="dialog_box_pic"/>
                        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}
                                    className='dialog'>
                            <TypingEffect text={allText[dialogIndex]} duration={0.05} key={dialogIndex}/>
                        </motion.div>
                        <motion.button variants={DialogSkipButton} onClick={skipDialog} className="skipButton">
                            <img src="/pic/skip_icon.png" alt="" />
                        </motion.button>
                    </motion.div>

                    {checkNowEvent(1) &&
                        <ShowOptions label1="前往家園" label2="開啟資訊書" label3="聊天"
                                     func1={() => {
                                         setEnterStatus(false);
                                         navigate('/home')
                                     }}
                                     func2={() => {
                                         setEnterStatus(true);
                                         navigate('/home')
                                     }}
                                     func3={() => {
                                         window.alert("hello");
                                         console.log()
                                     }}/>
                    }
                </motion.div>
            </div>
        </div>
    );
}

export default Start;