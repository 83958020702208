import React, {useEffect, useState} from 'react';
import {
    ChangePageLabel,
    ViewStatusBook
} from "./AnimationSet";
import {motion} from "framer-motion";
import Homepage from './Homepage';
import Portfolio from './Portfolio';
import Past from './Past';

const DefaultStatusBook = () =>{
    return(
        <div/>
    );
}

const StatusBook = ({closeBook, setCloseBook, setBookUrl, bookUrl}) =>{
    const [pageIndex, setPageIndex] = useState(1);
    const StatusPages = [
        {label: '首頁', index: 1},
        {label: '經歷', index: 2},
        {label: '作品集', index: 3}
    ]
    let page = null;

    useEffect(() =>{
        document.getElementById(pageIndex).style.clipPath = 'polygon(22% 0%, 100% 0%, 90% 50%, 100% 100%, 22% 100%)';
    },[pageIndex])

    switch(pageIndex){
        case 1:
            page = <Homepage/>;
            break;
        case 2:
            page = <Past/>;
            break;
        case 3:
            page = <Portfolio/>;
            break;
        default:
            page = <DefaultStatusBook/>;
            break;
    };

    return (
        <motion.div variants={ViewStatusBook} initial="initial" animate="animate" exit="exit" className="statusbook">
            {closeBook && <img src={bookUrl} className="book" alt="" />}
            <motion.ul variants={ChangePageLabel} className="options">
            {StatusPages.map((page, index) => (
                <li key={index}>
                    <button onClick={() => setPageIndex(()=>{
                        if(pageIndex !== 0){
                            document.getElementById(pageIndex).style.clipPath = 'polygon(22% 0%, 90% 0%, 80% 50%, 90% 100%, 22% 100%)';
                        }
                        document.getElementById(page.index).style.clipPath = 'polygon(22% 0%, 100% 0%, 90% 50%, 100% 100%, 22% 100%)';
                        return page.index;
                    })} id={index+1}><div>{page.label}</div></button>
                </li>
            ))}
            </motion.ul>
            <div className="bookdata">
                <motion.div variants={ChangePageLabel}>{page}</motion.div>
            </div>
            <motion.button variants={ChangePageLabel} className="closebook" onClick={() => {
            setBookUrl((prev) => (prev === "/pic/book.gif") ? "/pic/book.gif?" : "/pic/book.gif");
            setCloseBook(false);
        }}>
            close
        </motion.button>
    </motion.div>
    );
};

export default StatusBook;