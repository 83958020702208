export const NormalTransition = {
    initial: { opacity: 0, zIndex: 0},
    animate: { opacity: 1, zIndex: 1, transition: {duration: 1.5}},
    exit: { opacity: 0, zIndex: 0, background: 'linear-gradient(to right, transparent 0%, black 100%)', transition: { duration: 2, ease: 'easeInOut' } }
};

/*StartPage*/
export const OpenInputBox = {
    initial: { opacity: 0},
    animate: { opacity: 1, transition: {duration: 0.5, delay:0.5}},
    exit: {opacity: 0, transition: {duration: 0.5}}
};

const steps = 20;
const times = Array.from({length: steps*2}, (_, i) => ((i+1) % 2 === 0 ? (i+1) / (steps*2) : i / (steps*2)));

const StartAnimation = (steps) => {
    const clipPath = [];

    for (let i = 0; i < steps; i++) {
        const percentage = 100/steps;
        const startPercentage = i * percentage;
        const endPercentage = (i + 1) * percentage;

        clipPath.push(`polygon(${startPercentage}% 0%, ${endPercentage}% 0%, ${endPercentage}% 0%, 100% 0%, 100% 100%, ${startPercentage}% 100%)`);
        clipPath.push(`polygon(${startPercentage}% 100%, ${endPercentage}% 100%, ${endPercentage}% 0%, 100% 0%, 100% 100%, ${startPercentage}% 100%)`);
    }
    return clipPath;
};

export const StartPage ={
    initial: {opacity:1, clipPath:'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'},
    animate: {opacity:1, transition: {staggerChildren: 1}},
    exit: {opacity:1, clipPath: StartAnimation(steps),
        transition: {duration:2.5, type: 'tween', times: times}},
    exit2: {opacity:1, clipPath: StartAnimation(steps),
        transition: {duration:2.5, type: 'tween', delay: 0.05, times: times}},
    exit3: {opacity:1, clipPath: StartAnimation(steps),
        transition: {duration:2.5, type: 'tween', delay: 0.075, times: times}},
    exit4: {opacity:1, clipPath: StartAnimation(steps),
        transition: {duration:2.5, type: 'tween', delay: 0.1, times: times}}
};

export const StartScene = {
    initial: { opacity: 0, translateX:'-10%', translateY: '-30%'},
    animate: { opacity: 1, translateX:'0%', translateY: '0%', transition: { duration: 1, type: 'tween', ease: 'easeInOut', delayChildren: 0.5, staggerChildren: 0.3} },
};

export const StartChara = {
    initial: {opacity: 0, top: '100vh', right:'10vw'},
    animate: {opacity: 1, top: '5vh', right:'3vw', transition: { duration: 1, type:'tween', ease: 'easeInOut'}},
};

export const StartCharaPortrait = {
    initial: {opacity: 0, top: '70vh', left:'-60vw'},
    animate: {opacity: 1, top: '3vh', left:'-36vw', transition: { duration: 1, type:'tween', ease: 'easeInOut'}},
};

export const StartCharaMobile = {
    initial: {opacity: 0, top: '100vh', right:'10vw'},
    animate: {opacity: 1, top: '5vh', right:'3vw', transition: { duration: 1, type:'tween', ease: 'easeInOut'}},
    landscape: {opacity: 1, top: '5vh', right:'3vw', transition: { duration: 1, type:'tween', ease: 'easeInOut'}},
    portrait: {opacity: 1, top: '7vh', right:'-50vw', transition: { duration: 1, type:'tween', ease: 'easeInOut'}},
};

export const StartOptionsUl = {
    initial: {opacity: 0},
    animate: {opacity: 1, transition: { duration: 0.1, delayChildren:0.5, staggerChildren: 0.5}}
};

export const StartOptions = {
    initial: {scaleX: 0, pointerEvents: 'none'},
    animate: {scaleX: 1, pointerEvents: 'all', transition: { duration: 0.5, type:'tween', ease: 'easeInOut', delayChildren: 0.3}},
};

export const StartOptionsText = {
    initial: {opacity: 0},
    animate: {opacity: 1, transition: { duration: 0.4}}
};

export const StartDialog = {
    initial: {opacity: 0},
    animate: {opacity: 1, transition: { duration: 1, type:'tween', ease: 'easeInOut', delayChildren: 1}},
};

export const DialogSkipButton = {
    initial: {opacity: 0, pointerEvents: 'none'},
    animate: {opacity: 1, pointerEvents: 'all', transition: { duration: 1, type:'tween', ease: 'easeInOut'}},
};

/*StatusBook*/
export const ViewStatusBook = {
    initial: {opacity: 0},
    animate: {opacity: 1, transition: {duration: 0.5, delayChildren: 4.2}},
    exit: {opacity: 0, transition: {duration: 0.5, delay: 4.2}}
}
export const ChangePageLabel = {
    initial: {opacity: 0},
    animate: {opacity: 1, transition: {duration: 2}},
    exit: {opacity: 1, transition: {duration: 1}}
}

export const ViewPortfolio = {
    initial: { opacity: 0, zIndex: 0},
    animate: { opacity: 1, zIndex: 1, transition: {duration: 1.5, delay: 1}},
    exit: { opacity: 0, zIndex: 0, background: 'linear-gradient(to right, transparent 0%, black 100%)', transition: { duration: 2, ease: 'easeInOut' } }
};