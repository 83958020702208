import React, {useEffect, useState} from "react";

const TypingEffect = ({ text, duration }) => {
    const [visibleText, setVisibleText] = useState('');

    useEffect(() => {
        let timeout;
        let i = 0;

        const typeNextChar = () => {
            if (i <= text.length) {
                setVisibleText(text.slice(0, i));
                i++;
                timeout = setTimeout(typeNextChar, duration*1000);
            }
            else if (text !== ''){
                setVisibleText(prev => (prev.endsWith(' ...') ? text   : prev + ' ...'));
                timeout = setTimeout(typeNextChar, 700);
            }
        };

        typeNextChar();

        return () => {
            clearTimeout(timeout);
        };
    }, [duration, text]);

    return (
        <div>
            <span>{visibleText}</span>
        </div>
    );
};

export default TypingEffect;