import React, { useEffect, useRef, useState } from 'react';
import useFetch from "../plugins/useFetch";
import {motion} from "framer-motion";
import {NormalTransition} from "./AnimationSet";

const EachBlogBlock = ({ title, content, time, img}) => {
    return (
        <div className="senba_blog">
            <div className="blog_block">
                <span>標題：{title}</span><br />
                <span>內文：{content}</span><br />
                <span>發布時間：{time}</span><br />
                <img src={img} alt="" />
            </div>
        </div>
    )
}

const SenbaBlog = () => {
    const bottomRef = useRef(null);
    const {loading, error, data} = useFetch("http://localhost:1337/api/senba-blogs?populate=*");
    const [blogData, setBlogData] = useState([]);

    useEffect(() => {
        if (error) {
            console.error("cannot loading blog...");
        }
        else {
            if (loading) {
                console.log("loading");
            }
            else {
                setBlogData(data.data);
                setTimeout(() => {
                    bottomRef.current.scrollIntoView({behavior: 'smooth', block: 'end'});
                }, 1500); // 设置延迟时间，单位为毫秒
            }
        }
    }, [error, loading, data]);

    return (
        <motion.div variants={NormalTransition} initial="initial" animate="animate" exit="exit">
            <div className="top_blank" />
            {blogData.map((data, index) => (
                <div key={index}>
                    <EachBlogBlock title={data.attributes.Title}
                                   content={data.attributes.Content}
                                   time={data.attributes.PublishTime}
                                   img={`http://localhost:1337${data.attributes.Picture.data.attributes.url}`}
                    />
                    <EachBlogBlock title={data.attributes.Title}
                                   content={data.attributes.Content}
                                   time={data.attributes.PublishTime}
                                   img={`http://localhost:1337${data.attributes.Picture.data.attributes.url}`}
                    />
                    <EachBlogBlock title={data.attributes.Title}
                                   content={data.attributes.Content}
                                   time={data.attributes.PublishTime}
                                   img={`http://localhost:1337${data.attributes.Picture.data.attributes.url}`}
                    />
                    <EachBlogBlock title={data.attributes.Title}
                                   content={data.attributes.Content}
                                   time={data.attributes.PublishTime}
                                   img={`http://localhost:1337${data.attributes.Picture.data.attributes.url}`}
                    />
                    <EachBlogBlock title={data.attributes.Title}
                                   content={data.attributes.Content}
                                   time={data.attributes.PublishTime}
                                   img={`http://localhost:1337${data.attributes.Picture.data.attributes.url}`}
                    />
                </div>
            ))}
            <div ref={bottomRef} className="top_blank"/>
        </motion.div>
    );
}

export default SenbaBlog;
