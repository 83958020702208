import { useState, useEffect } from 'react';

const useRWD = () => {
    const [device, setDevice] = useState("mobile");

    const handleRWD = () => {
        if (window.innerWidth > window.innerHeight) {
            setDevice("pc");
        } else {
            setDevice("mobile");
        }
    }

    useEffect(() => {
        handleRWD();
        window.addEventListener('resize', handleRWD);
        return () => {
            window.removeEventListener('resize', handleRWD);
        }
    }, [device]);


    return device;
}

export default useRWD;