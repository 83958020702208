import {motion} from "framer-motion";
import {ViewPortfolio} from "./AnimationSet";
import React, {useEffect, useState} from "react";

const ViewPortfolioContent = ({portfolioText, portfolioPath}) =>{
    //const [textIndex, setTextIndex] = useState(0);
    //const [portfolioType, setPortfolioType] = useState((-1));
    const [portfolioData, setPortfolioData] = useState([]);
    const [portfolioIndex, setPerfolioIndex] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("/text/portfolioData/"+portfolioPath+".json");
                const jsonData = await response.json();
                setPortfolioData(jsonData);
            } catch (error) {
                console.error('Cannot open file', error);
            }
        };

        fetchData();
    }, [portfolioPath]);

    return (
        <motion.div variants={ViewPortfolio} initial="initial" animate="animate" exit="exit">
            <div style={{position: "absolute", zIndex: '1'}} className="bookcontainer">
                <div className="leftpart">
                    <motion.div layoutScroll className="scrollbox">
                        {portfolioText.map((allText, index) => (
                            <div key={index}>
                                <div className="portfolioTitle">{allText.typeName}</div>
                                {allText.portfolio.map((text, innerIndex) => (
                                    <div key={innerIndex} className="list">
                                        <button onClick={() => {setPerfolioIndex(portfolioText[index].portfolio[innerIndex].data)}} className="fakebutton">
                                            <div className="portfolioText"> {text.title} </div>
                                        </button>
                                    </div>
                                ))}
                                <div style={{marginBottom: '3.5vh'}}></div>
                            </div>
                        ))}
                    </motion.div>
                </div>
                <div className="rightpart">
                    {portfolioIndex !== null && <div>
                        {portfolioData[portfolioIndex].img_f && <img src={"/pic/portfolio/"+portfolioData[portfolioIndex].img_f} alt="" style={{width: '30vw'}}/>}
                        {portfolioData[portfolioIndex].text.map((data) => (
                            <div className="eachline" style={{whiteSpace: 'pre-line'}}>{data}</div>))}
                        {portfolioData[portfolioIndex].img_b && <img src={"/pic/portfolio/"+portfolioData[portfolioIndex].img_b} alt="" style={{width: '30vw'}}/>}
                    </div>}
                </div>
            </div>
        </motion.div>
    );
}

export default ViewPortfolioContent;