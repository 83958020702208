import React, {useState} from 'react';
import {NormalTransition} from "./AnimationSet";
import {motion} from "framer-motion";

const Past = ({setInitialStatus, initialTimes}) =>{
    const [textIndex, setTextIndex] = useState(0);
    const text = [
        {name: null, text:[], img: null},
        {name: null, text:["哈囉你好嗎我很喔","真的嗎"], img: "/pic/senba.png"},
        {name: null, text:["我很不好","?/"], img: "/pic/logo.png"}
    ];

    return (
        <motion.div variants={NormalTransition} initial="initial" animate="animate" exit="exit">
            <div style={{position: "absolute"}} className="bookcontainer">
                <div className="leftpart">
                    <motion.div layoutScroll className="scrollbox">
                        {Array.from({length: 50}, (_, index) => (
                            <div key={index} className="list">
                                <button onClick={() =>{setTextIndex(index+1)}} className="fakebutton">hihihi {index+1}</button>
                            </div>
                        ))}
                    </motion.div>
                </div>
                <div className="rightpart">
                    {text[textIndex].text.map((data) => (
                        <div className="eachline">{data}<br/></div>
                    ))}
                    <img src={text[textIndex].img} alt="" />
                </div>
            </div>
        </motion.div>
    );
}

export default Past;