import React, {useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import Start from "./Start";
import Home from "./Home";
import SenbaBlog from "./SenbaBlog";

const SiteRoutes = () => {
    const location = useLocation();
    const [enterStatus, setEnterStatus] = useState(false)

    return(
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route index element={<Start setEnterStatus={setEnterStatus}/>} />
                <Route path="/Home" element={<Home ifOpenBook={enterStatus} />} />
                <Route path="/Blog" element={<SenbaBlog />} />
            </Routes>
        </AnimatePresence>
    )

}

export default SiteRoutes