import React from 'react';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import SiteRoutes from "./pages/SiteRoutes";

function App() {

    return (
        <Router>
            <div className="App">
                <main>
                    <SiteRoutes/>
                </main>
                {/*<footer>*/}
                {/*    <p>©2024 HaneNoAida</p>*/}
                {/*</footer>*/}
            </div>
        </Router>
    );
}


export default App;