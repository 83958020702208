import React, {useEffect, useState} from 'react';
import {NormalTransition} from "./AnimationSet";
import {motion} from "framer-motion";

const Homepage = ({setInitialStatus, initialTimes}) =>{
    //const [detailText, setDetailText] = useState("");
    const [detailIndex, setDetailIndex] = useState("");
    const [detailText, setDetailText] = useState("");

    useEffect(() => {
        const fetchDetailText = async () => {
            try {
                const response = await fetch("/text/DetailText.json");
                const jsonData = await response.json();
                setDetailText(jsonData);
            } catch (error) {
                console.error('Cannot open file', error);
            }
        };

        fetchDetailText();
    }, [detailIndex]);

    return (
        <motion.div variants={NormalTransition} initial="initial" animate="animate" exit="exit">
            <div style={{position: "absolute"}} className="bookcontainer">
                <div className="leftpart">
                    <div className="chara charaname">
                        <button className="fakebutton" onClick={()=>{setDetailIndex("level")}}>等級：Lv5</button>
                        <button className="fakebutton" onClick={()=>{setDetailIndex("name")}}>暱稱：千羽</button>
                        <button className="fakebutton" onClick={()=>{setDetailIndex("class")}}>位階：大學</button>
                    </div>
                    <div className="chara charadata">
                        <img src="/pic/senba.png" alt=""/>
                        <div className="databar">
                            <h>個人資料</h>
                            <div>種族：
                                <button className="fakebutton" onClick={()=>{setDetailIndex("race")}}>人類</button>
                            </div>
                            <div>年齡：
                                <button className="fakebutton" onClick={()=>{setDetailIndex("age")}}>19 y</button></div>
                            <div>職業：
                                <button className="fakebutton" onClick={()=>{setDetailIndex("job")}}>學生</button></div>
                            <div>專業：
                                <button className="fakebutton" onClick={()=>{setDetailIndex("skill")}}>資訊相關</button></div>
                            <div>興趣：
                                <button className="fakebutton" onClick={()=>{setDetailIndex("interest")}}>看動漫</button></div>
                            <div>目標：
                                <button className="fakebutton" onClick={()=>{setDetailIndex("goal")}}>遊戲製作</button></div>
                        </div>
                    </div>
                    <div className="chara charaintro">
                        <h>個人簡介：</h>
                        <div>&emsp;&emsp;我是一名熱愛探索的高中生，住在臺灣南部，對於初見面的人會有點怕生，但熟悉了之後就會開放許多，因為我認為笑容能夠帶來幸福，所以平時總是樂觀面對每件事情。</div>
                    </div>
                </div>
                <div className="rightpart">
                    <motion.div variants={NormalTransition} style={{whiteSpace: 'pre-line'}} className="detailtext">
                        {detailIndex !== "" && detailText[detailIndex].text.map((data) => (
                            <div>{data}</div>
                        ))}
                    </motion.div>
                </div>
            </div>
        </motion.div>
    );
}

export default Homepage;