import React, {useEffect, useRef} from "react";
import TimeJudge from "./TimeJudge";

const ChangeBackground = ({setIndex}) =>{
    const timeStatusRef = useRef(0);

    useEffect(() => {
        timeStatusRef.current.judge('06:00:00', '18:00:00') && setIndex(1);
        timeStatusRef.current.judge('06:00:00', '12:00:00') && setIndex(2);
        timeStatusRef.current.judge('18:00:00', '24:00:00') && setIndex(3);
        timeStatusRef.current.judge('00:00:00', '06:00:00') && setIndex(4);
    }, [setIndex, timeStatusRef]);

    return(
        <div>
            <TimeJudge ref={timeStatusRef} />
        </div>
    )
}

export default ChangeBackground;