import React, {useImperativeHandle, forwardRef} from "react";

const TimeJudge = forwardRef((prop, ref) => {

    const judgeTime = (begin, end) =>{
        var curDate = new Date();
        var [beginHour, beginMinute, beginSecond] = begin.split(':').map(Number);
        var [endHour, endMinute, endSecond] = end.split(':').map(Number);

        var curTime = curDate.getHours() * 3600 + curDate.getMinutes() * 60 + curDate.getSeconds();
        var beginTime = beginHour * 3600 + beginMinute * 60 + beginSecond;
        var endTime = endHour * 3600 + endMinute * 60 + endSecond;

        if (curTime >= beginTime && curTime < endTime) {
            return true;
        }
        return false;
    }
    useImperativeHandle(ref, () => ({
        judge: judgeTime
    }));

    return (
        <div>
            {/* 子组件的内容 */}
        </div>
    );
})

export default TimeJudge;