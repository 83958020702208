import React, {useState} from 'react';
import {motion} from "framer-motion";
import ViewPortfolioContent from "./ViewPortfolioContent";
import {NormalTransition, ViewPortfolio} from "./AnimationSet";

const Portfolio = () =>{
    //遊戲開發
    const originalGame = [
        {title: "大學生活模擬器", data: "originalGame01"},
    ];

    const derivativeGame = [
        {title: "LoveLive!　虹咲學園 GO!", data: "derivativeGame01"},
    ];

    const collabGame = [
        {title: "暫無", data: "collabGame01"},
    ];

    const webGame = [
        {title: "像素貪食蛇", data: "webGame01"},
        {title: "飛碟吸金", data: "webGame02"},
        {title: "英文高單問答", data: "webGame03"},
        {title: "數字版華榮道", data: "webGame04"}
    ];

    const pythonGame = [
        {title: "1A2B 猜謎", data: "pythonGame01"},
    ];

    const gameDevelop = [
        {typeName: "獨立原創遊戲", portfolio: originalGame},
        {typeName: "同人二創遊戲", portfolio: derivativeGame},
        {typeName: "協力開發遊戲", portfolio: collabGame},
        {typeName: "網頁小遊戲", portfolio: webGame},
        {typeName: "Python", portfolio: pythonGame},
    ]

    //個人創作
    const selfDesign = [
        {title: "個人 Logo", data: "selfDesign01"},
        {title: "個人 Icon", data: "selfDesign02"},
        {title: "個人衣服設計（T-shirt）", data: "selfDesign03"},
    ]

    const selfArtWork = [
        {title: "千羽人設（Padoru 版本）", data: "selfArtWork01"},
        {title: "Q 版蛇娘", data: "selfArtWork02"},
        {title: "版畫—藍色美人魚", data: "selfArtWork03"},
        {title: "音樂少女—繆拉", data: "selfArtWork04"},
    ]

    const derivativeArtWork = [
        {title: "伊莉雅電繪動畫", data: "derivativeArtWork01"},
        {title: "仙草生日賀圖", data: "derivativeArtWork02"},
    ]

    const animationComment = [
        {title: "不起眼女主角培育法", data: "animationComment01"},
        {title: "阿爾蒂", data: "animationComment02"},
        {title: "機動戰士鋼彈 0080", data: "animationComment03"},
    ]

    const literatureWork = [
        {title: "〈新詩〉蛻變", data: "literatureWork01"},
        {title: "〈散文〉你是否也曾經想過", data: "literatureWork02"},
        {title: "〈傳記〉蔡清樹先生", data: "literatureWork03"},
        {title: "〈傳記〉陳家璋先生", data: "literatureWork04"},
    ]

    const novelWritten = [
        {title: "平凡少女的學園生活", data: "novelWritten01"},
        {title: "音域與音樂少女", data: "novelWritten02"},
    ]

    const personalWorks = [
        {typeName: "原創設計", portfolio: selfDesign},
        {typeName: "原創畫作", portfolio: selfArtWork},
        {typeName: "二創畫作", portfolio: derivativeArtWork},
        {typeName: "動畫心得分享", portfolio: animationComment},
        {typeName: "文學作品", portfolio: literatureWork},
        {typeName: "小說與世界觀創作", portfolio: novelWritten}
    ]

    //其他作品
    const manualCreation = [
        {title: "潤羽露西婭紙模型", data: "manualCreation01"},
    ]

    const funCreation = [
        {title: "風中戰慄的騎士", data: "funCreation01"},
        {title: "可愛小吐司", data: "funCreation02"},
        {title: "詭譎的紳士", data: "funCreation03"},
        {title: "微笑薯餅叔叔", data: "funCreation04"},
    ]

    const otherWorks = [
        {typeName: "手工創作", portfolio: manualCreation},
        {typeName: "趣味創作", portfolio: funCreation},
    ]

    //影音剪輯
    const vtuberClip = [
        {title: "潤羽露西婭初配信全熟", data: "vtuberClip01"},
        {title: "七詩無名—我是誰？", data: "vtuberClip02"},
        {title: "椎名菜羽歌回剪輯", data: "vtuberClip03"},
        {title: "微笑薯餅叔叔", data: "vtuberClip04"},
    ]

    const effectAnimation = [
        {title: "七詩無名—我是誰？", data: "effectAnimation01"},
    ]

    const madClip = [
        {title: "暫無內容", data: "madClip01"},
    ]

    const personalVideo = [
        {title: "學測數學題目解析", data: "personalVideo01"},
        {title: "高中美術版畫故事短片", data: "personalVideo02"},
        {title: "虹咲學園小遊戲—試玩介紹影片", data: "personalVideo03"},
    ]

    const clipSupport = [
        {title: "協力播放清單", data: "clipSupport01"},
    ]

    const videoEditing = [
        {typeName: "Vtuber 精華剪輯", portfolio: vtuberClip},
        {typeName: "特效動畫", portfolio: effectAnimation},
        {typeName: "mad 剪輯", portfolio: madClip},
        {typeName: "個人影片", portfolio: personalVideo},
        {typeName: "其他頻道精華剪輯協助", portfolio: clipSupport},
    ]

    //資訊相關
    const convenientTools = [
        {title: "推特藍鳥圖標復原擴充插件", data: "convenientTools01"},
        {title: "LoveLive虹咲學園　瀏覽器主題", data: "convenientTools02"},
        {title: "Discord爬蟲機器人", data: "convenientTools03"},
    ];

    const technologyArticle = [
        {title: "AI臉部辨識我最行", data: "technologyArticle01"},
        {title: "校園植物VR探索", data: "technologyArticle02"},
        {title: "虹咲二創遊戲製作過程探討", data: "technologyArticle03"},
    ];

    const technologyResearch = [ //影音剪輯、文藝創作、其他作品
        {typeName: "便利工具與插件", portfolio: convenientTools},
        {typeName: "論文、報告", portfolio: technologyArticle},
    ]

    const [nowType, setNowType] = useState((-1))
    const [ifChoosed, setIfChoosed] = useState([false, false, false, false, false])
    const allType = [
        {title: "遊戲開發", script: gameDevelop, dataPath: "gameDevelop"},
        {title: "個人作品", script: personalWorks, dataPath: "personalWorks"},
        {title: "其他作品", script: otherWorks, dataPath: "otherWorks"},
        {title: "影音剪輯", script: videoEditing, dataPath: "videoEditing"},
        {title: "資訊相關", script: technologyResearch, dataPath: "technologyResearch"},
    ]
    const radius = 14;

    const angles = [0, (2 * Math.PI) / 5, (4 * Math.PI) / 5, (6 * Math.PI) / 5, (8 * Math.PI) / 5];
    let x = [];
    let y = []
    for(let i=0; i<5; i++){
        x.push(radius * Math.sin(angles[i]) + 13.28);
        y.push(radius * -1 * Math.cos(angles[i]) + 16.1);
    }

    return(
        <motion.div className="portfolioCircle" variants={NormalTransition} initial="initial" animate="animate" exit="exit">
            {allType.map((data, index) => (
                (nowType === (-1) || nowType === index) &&
                <motion.div variants={NormalTransition}>
                    <motion.button layout transition={{duration: 0.8, type: 'tween', ease: 'easeInOut'}}
                               key={index} className="circle"
                               style={{
                                   backgroundImage: `url(/pic/portfolio_icon/${index+1}.png)`,
                                   left: ifChoosed[index] ? '4.2%' : `${x[index]}vw`,
                                   top: ifChoosed[index] ? '11%' : `${y[index]}vw`,
                                   width: ifChoosed[index] ? '32vw' : '10vw',
                                   height: ifChoosed[index] ? '32vw' : '10vw',
                                   borderRadius: ifChoosed[index] ? '0vw' : '5vw',
                                   opacity: ifChoosed[index] ? '0.2' : '1'
                               }}
                               onClick={() => {
                                   setNowType(index);
                                   setIfChoosed((prev) => {
                                       prev[index] = true
                                       return prev
                                   })
                               }}>{/*nowType === (-1) && data.title*/}</motion.button>
                </motion.div>
            ))}
            {nowType !== (-1) && <ViewPortfolioContent portfolioText={allType[nowType].script} portfolioPath={allType[nowType].dataPath}/>}
            {nowType !== (-1) && <motion.button variants={ViewPortfolio} className="returnbutton" onClick={() =>{
                setNowType((-1));
                setIfChoosed([false, false, false, false, false]);
            }}>back</motion.button>}
            {nowType === (-1) && <img src="/pic/Logo.png" className="logo" alt="" />}
        </motion.div>
    );
}

export default Portfolio;